import React, { Component }  from 'react'
import styled from 'styled-components';
import OktaAuth from '@okta/okta-auth-js';
import { withAuth } from '@okta/okta-react';

const FormGrid = styled.form`
    height:100%;
    witdh:100%
    display:grid;
    grid-template-columns: 2em 1fr 3fr 2em;
    grid-template-row: 2em repeat(2, 1fr) 1em auto 1em;
    grid-template-areas:
      "top top top top"
      "left labeluser inputuser right"
      "left labelpass inputpass right"
      "left submit submit right"
      "left error error right"
      "bottom bottom bottom bottom"
`;
const borderRadius = '16px'
const Label = styled.label`
  justify-self: end;
  font-size: 1.5em;
  padding:1em;
  align-self: center;
`;

const UserLabel = styled(Label)`
  grid-area: labeluser;
`;

const PassLabel = styled(Label)`
  grid-area: labelpass;
`;

const Input = styled.input`
  font-size: 1em;
  height: 1.5em;
  width:100%;
  justify-self: start;
  align-self: center;
`;

const UserInput = styled(Input)`
  grid-area: inputuser;
`;

const PassInput = styled(Input)`
  grid-area: inputpass;
`;

const SubmitInput = styled.input`
  grid-area: submit;
  width:100px;
  height:30px;
  font-size:2em;
  
  justify-self: center;
`;

const Error = styled.p`
  grid-area: error;
  color: red;
`
const Bottom = styled.div`
  grid-area: bottom;
  background-color: #187B57;
  border-radius: 0 0 ${borderRadius} ${borderRadius};
`

const P = styled.p`
text-align-last:right
padding-right:1em;
margin-top:2em;
margin-bottom:1em;
color:white;
`



interface ILoginFormState {
  sessionToken?:any;
  username?:string;
  password?:string;
  errormessage?:string;
}

interface ILoginFormProps  {
  auth?:any;
  baseUrl?:any;
}

const LoginForm =  withAuth(class LoginForm extends Component<ILoginFormProps, ILoginFormState> {
    oktaAuth:any;

    constructor(props:ILoginFormProps) {
      super(props);
      this.state = {
        sessionToken: null,
        username: '',
        password: '',
        errormessage: '',
      }
  
      this.oktaAuth = new OktaAuth({ url: props.baseUrl });
  
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleUsernameChange = this.handleUsernameChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }
  
    handleSubmit(e:any) {
      e.preventDefault();
      this.oktaAuth.signIn({
        username: this.state.username,
        password: this.state.password
      })
      .then((res:any) => {
        this.setState({sessionToken: res.sessionToken, errormessage: ''})})
      .catch((err:any) => {
        this.setState({errormessage: 'Login failed'})
        console.log('Found an error', err)
      });
    }
  
    handleUsernameChange(e:any) {
      this.setState({username: e.target.value});
    }
  
    handlePasswordChange(e:any) {
      this.setState({password: e.target.value});
    }
  
    render() {
      if (this.state.sessionToken) {
        this.props.auth.redirect({sessionToken: this.state.sessionToken});
        return null;
      }
  
      return (
        <FormGrid onSubmit={this.handleSubmit}>
    
          <UserLabel>
            Username:
            </UserLabel>
            <UserInput
              id="username" type="text"
              value={this.state.username}
              onChange={this.handleUsernameChange} />
              <PassLabel>
            Password:
            </PassLabel>
            <PassInput
              id="password" type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange} />
         
          <SubmitInput id="submit" type="submit" value="Submit" ></SubmitInput>
          { this.state.errormessage ? <Error>{this.state.errormessage}</Error> : <></>}
          <Bottom><P>powered by <b>BCG Gamma</b></P></Bottom>
        </FormGrid>
      );
    }
  });

  export default LoginForm
