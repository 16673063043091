import React from 'react';
import LazyLoad from 'react-lazyload';

export interface GifProps {
    src: string;
    maxHeight?: string;
    height?:string;
    reloading?: boolean
}



const Gif: React.SFC<GifProps> = (props:any) => {
    const maxiHeight = props.maxHeight ?  {maxHeight: props.maxHeight} : {}
    const setheight:Object = props.height ?  {height: props.height} : {}
    return (
        <LazyLoad unmountIfInvisible={props.reloading}>
            <img src={props.src + '?a=' + Math.random()} style={{ maxWidth: '100%' , ...maxiHeight, ...setheight }} alt={`${props.src}`} />
        </LazyLoad>
    );
}

export default Gif;