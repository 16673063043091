import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { connect } from 'react-redux';

import ScrollHandler from './components/Scrollhandler';
import Carousel from './components/Carousel';
import ToolOverview from './components/ToolOverview';
import Menu from './components/menu/menu';
import Banner from './components/Banner';
import Philosophy from './components/Philosophy';
import Tool from './components/Tool';
import Approach from './components/articles/approach';
import Team from './components/articles/team';
import UseCaseOverview from './components/UseCaseOverview';
import Footer from './components/Footer'

import { pages, team } from './content';


const DividerDiv = styled.div`
    height: 3em;
`;

export interface MainPageProps {
    ToolOrder?: any;
    toolsContent?:any;
    philosophiesContent?:any;
}

const MainPage: React.SFC<MainPageProps> = (props:any) => {

    const philosophies:any = Object.values(props.philosophiesContent)
    const toolsInOverview:any = Object.values(props.toolsContent).filter((tool:any) => tool.inOverview);
    // const toolsInCarousel:any = Object.values(props.toolsContent).filter((tool:any) => tool.url && true);
    const toolsInCarousel:any = Object.values(props.toolsContent).filter((tool:any) => tool.inOverview);
    const activeToolOrder:any = props.ToolOrder.activeToolOrder;

    return (
        <BrowserRouter>
            <ScrollHandler />
            <Menu options={pages} id='menu' />
            <Banner id='home' />
            <a id='philosophy' href='#philosophy' />
            <Carousel steps={philosophies.length} active={0}>
                {
                    philosophies.map((philosophy:any, index:number) => 
                    {return<Philosophy
                            withCarousel
                            key={`philosophy-${index}`}
                            id={`philosophy-${index}`}
                            gif={philosophy.gif}
                            title={philosophy.title}
                            reloading={philosophy.reloading ? true : false } 
                            />}
                        )
                }
            </Carousel>
            <DividerDiv />
            <a id='application' href='#application' />
            <Carousel hasOverview
                steps={toolsInCarousel.length + 1} 
                active={activeToolOrder === -1 ? 0 : toolsInCarousel.findIndex((tool:any) => tool.order === activeToolOrder)+1 }
            >
                <ToolOverview id='tool-overview' tools={toolsInOverview} />
                {
                    toolsInCarousel
                        .sort((a:any, b:any) => Number(a.order) - Number(b.order))
                        .map((tool:any) => 
                            <Tool key={`tool-${tool.id}`} id={`tool-${tool.id}`} tool={tool} />
                        )
                }
            </Carousel>
            <UseCaseOverview id='application-overview' />
            <Approach id='approach' />
            <Team id='team' team={team} />
            <Footer />
        </BrowserRouter>
    );
}

const mapStateToProps= (state:any) => {
    return{
        ToolOrder: state.tools,
        toolsContent: state.variations.tools,
        philosophiesContent: state.variations.philosophies
    }
}
 
export default connect(mapStateToProps)(MainPage);
