
const htmlUlFactory = (htmlobj) => {

    const liArray =  Object.values(htmlobj)
                    .map((li)=> {return wrapEl(
                        'li', 
                        Object.values(li).reduce((acc, curr)=>{
                            return acc += curr.el ? wrapEl(curr.el,curr.text,curr.style): curr.text
                        },'')
                        )})
    return wrapEl('ul', liArray.reduce((acc,curr)=>{return acc += curr},''))

}

const wrapEl = ( el , str, style) => {
    const stylestr = style ? `style="${style}"` : ''
    return  `<${el} ${stylestr}>${str}</${el}>`
}

export default htmlUlFactory