import React, { useState, MouseEvent } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import MenuButton from './menu-button';
import bcgLogo from '../../assets/images/bcg-logo.svg';

import AccountImage from './../AccountImage/index'


import { connect} from 'react-redux'

const MenuContainer = styled.div`
    display: inline-block;
    margin-left: 0 !important;
    position: fixed;
    z-index: 99;
    width: 100%;
    background-color: ${(props:any) => props.theme.colors.bg};
    box-shadow: 0px 1px 5px 1px grey;
`;

const Image = styled.img`
    max-height:55px;
    max-width:200px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;

`;

const ImageBCG = styled(Image)`
    max-height:73px;
    padding: 0;
    margin-left: 0;
    background-color: ${(props:any) => props.theme.colors.brand};
`;

export interface MenuProps {
    id: string;
    options: Array<string>;
    logos?:any,
    images?:any
}

const Menu: React.SFC<MenuProps> = (props:any) => {
    const [current, setCurrent] = useState('home');
    const handleClick = (e: MouseEvent) => {
        let target = e.target as HTMLInputElement;
        setCurrent((target.textContent || 'home').toLowerCase());
    }

    return (
        <MenuContainer {...props}>
            <Grid container direction='row'  justify='space-between' alignItems='center'>
                <Grid item sm={12} md={12} lg={4}>
                    <Grid container wrap='nowrap' direction='row'  justify='flex-start' alignItems='center'>
                        <ImageBCG src={bcgLogo} />
                        {Object.values(props.logos).map((logo:any, index:any) =>
                            <Image
                                key={`logo-${index}-${logo}`}
                                src={props.images[logo.name]}
                                {...logo.style}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid item sm={12} md={12} lg={8}>
                    <Grid container direction='row'  justify='flex-end' alignItems='center'>
                        {props.options.map((ref:any, index:any) =>
                            <MenuButton
                                key={`menu-${ref}-${index}`}
                                to={`/#${ref.toLowerCase()}`}
                                name={ref}
                                onClick={handleClick}
                                active={ref.toLowerCase() === current}
                            />  
                            )}
                            <AccountImage/>
                    </Grid>
                </Grid>
            </Grid>
        </MenuContainer>
    );
}

const mapStateToProps= (state:any) => {
    return{
        logos: state.variations.menu.logos,
        images: state.images
    }
}
 
export default connect(mapStateToProps)(Menu);