import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Column, MinusPadding } from '../../layouts';
import MacFrame from '../../assets/images/mac-frame.png';
import LabImg from '../../assets/images/innovation-lab.png';

const ButtonContainer = styled.div`
    position: absolute;
    display: block;
    font-size: 0.8em;
    bottom: calc(10% + 0.5em);
    left: 6%;
    z-index: 11;
`;

const Container = styled(Column)`
/* TODO: find a solution for this hard-coding to keep the MacFrame be scaled proportionally and not overlapped */
    max-width: calc(1.05 * (100vh - 73px - 52px));
    position: relative;
    display: flex;
    flex-flow: column;
    margin: auto;
    background-color: ${props => props.theme.colors.bg};
`;

const Image = styled.img`
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    width: 100%;
    z-index: 10;
`;

const DivInPad = styled.div`
    position: absolute; 
    left: 3.6%; 
    top: 5.1%; 
    height: 69.4%;
    width: 92.7%;
`;

const LabLogo = styled.img`
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    display: block;
    position: absolute;
    bottom: 10%;
    right: -6%;
    width: 40%;
    z-index: 11;
`;

export interface PadProps {
    children: React.ReactNode;
    inLab: boolean;
    ref?: any;
    toggleFullscreen: () => any;
}

const Pad = React.forwardRef((props:PadProps, ref) => {
    return (
        <Container ref={ref} {...props}>
            <MinusPadding>
                <DivInPad {...props}>
                    {props.children}
                </DivInPad>
                <Image src={MacFrame} />
                {props.inLab && <LabLogo src={LabImg} />}
                <ButtonContainer>
                    <Button onClick={props.toggleFullscreen}>Full screen</Button>
                </ButtonContainer>
            </MinusPadding>
        </Container>
    );
})

export default Pad;