import { createStore, combineReducers } from 'redux';
import toolsReducer  from './reducer/tools';
import variationsReducer from './reducer/variations';
import imagesReducer from './reducer/images'


const rootReducer = combineReducers({
    variations : variationsReducer,
    tools: toolsReducer,
    images: imagesReducer
})

export const store = createStore(rootReducer);

export type AppState = ReturnType<typeof rootReducer>;
 