import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

const Div = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
`;

const ScalableDiv = styled.div`
    display: block;
    position: absolute;
    width: 1366px;
    height: 768px;
    transform-origin: top left;
    transform: ${(props: { width: number }) => `scale(${Math.min(props.width / 1366, 1)})`};
`;

const IFrame = styled.iframe`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
`;

export interface ScreenProps {
    tool: any;
}

const Screen: React.SFC<ScreenProps> = (props:any) => {
    const { tool } = props;
    const vizContainer = useRef(null);
    const [frameWidth, setFrameWidth] = useState(0);

    useEffect(() => {
        // @ts-ignore
        if (vizContainer.current) setFrameWidth(vizContainer.current.offsetWidth);
        // @ts-ignore
        window.addEventListener("resize", () => setFrameWidth(vizContainer.current.offsetWidth));
    }, []);

    return (
        <Div ref={vizContainer} style={{backgroundColor: 'white'}}>
            <ScalableDiv width={frameWidth}>
            <LazyLoad >
                <IFrame
                    id={`iframe-screen-${tool.id}`}
                    allow="fullscreen"
                    src={`${tool.url}`}>
                </IFrame>
                </LazyLoad>
            </ScalableDiv>
        </Div>

    );
}

export default Screen;