// this File merges the main site settings with the speficfied version of the site
// it return a main javascript object with aal the correct settings

const _ = require('lodash');
const settingsFiles = require('./file-index.json')

const variations = (site) => {
    return (site.length > 0) ? importSiteVariations(site) : { }
}

const importSiteVariations = (site) => {

    if (settingsFiles[site]){
        let mergedSettings = {}
        for (var index in settingsFiles[site]) {
            let pathFile = settingsFiles[site][index].split('content/')[1]
            mergedSettings = _.merge({},mergedSettings, require('./'+ pathFile))
        }
        return mergedSettings
    }else{
        console.log(`there is no folder ${site} in the dir site-variations`)
        return {}
    }
}

const settings = _.merge({},variations('default'), variations(process.env.REACT_APP_SITE))
export default settings
