import React from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';

import ToolCell from './ToolCell';

const GridContainer = styled.div`

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 32vh);
    justify-content: stretch;
    align-content: stretch;
    justify-items: center;
    align-items: center;
    grid-gap: 1.5em;
`;


const Padding = styled.div`
    
   
    
`;

const H2 = styled.h2`
    font-weight: 700;
    line-height: 1.1em;
    padding-bottom: 1.4em;
    margin:0;
    font-size: 2em;
`;

export interface ToolOverviewProps {
    id: string;
    tools: Array<any>;
    tooloverview?: any;
    setActiveTool?: any;
    style?: any;
}

const ToolOverview: React.SFC<ToolOverviewProps> = (props: any) => {
    const handleClick = (tool: any) => {
        // dispatch action ro redux set active tool
        props.setActiveTool((typeof tool.order === 'number' ? tool.order : -1))
    }

    return (
        <Padding>
            <Grid
                container
                direction='row'
                justify='center'
                alignItems='flex-start'
                alignContent='stretch'
            >
                <Grid item sm={12} md={11} lg={11} xl={11}>
                    <Grid
                        container
                        direction='column'
                        justify='flex-start'
                        alignItems='center'
                    >
                        <Grid item>
                            <H2>{`${props.tools.length} ${props.tooloverview.title.text}`}</H2>
                            <GridContainer>
                                {props.tools.map((tool: any) =>
                                    <ToolCell key={`tool-cell-${tool.id}`} id={`tool-cell-${tool.id}`} tool={tool} handleClick={handleClick} />
                                )}
                            </GridContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Padding>
    );
}
const mapStateToProps = (state: any) => {
    return {
        tooloverview: state.variations.tooloverview
    }
}



const mapDispatchToProps = (dispatch: any) => {
    return {
        setActiveTool: (orderNumber: number) => dispatch({ type: 'SET_ACTIVE_TOOL', order: orderNumber })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolOverview);
