import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import SwipeableViews from 'react-swipeable-views';

import { connect } from 'react-redux'

const ButtonContainer = styled.div`
    flex: 0 1 auto;
`;
export interface CarouselProps {
    children: React.ReactNode;
    steps: number;
    active: number;
    id?: string;
    hasOverview?: boolean;
    resetActiveTool?:any ;
}
 
const Carousel: React.SFC<CarouselProps> = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const  handleNext = () => setActiveSlide(prev => prev + 1);
    const handleBack = () => setActiveSlide(prev => prev - 1);
    const goToOverview = () => {
        setActiveSlide(0); 
        // dispatch action to redux : 'RESET_ACTIVE_TOOL'
        props.resetActiveTool()
    }
    const handleChangeIndex = (index: number) => setActiveSlide(index);

    useEffect(() => {
        setActiveSlide(props.active);
    }, [props.active]);

    return ( 
    <div>
        <SwipeableViews index={activeSlide} onChangeIndex={handleChangeIndex}>
            { props.children }
        </SwipeableViews>
        <MobileStepper 
            steps={props.steps}
            position="static"
            activeStep={activeSlide}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={activeSlide === props.steps - 1}>
                    Next<KeyboardArrowRight />
                </Button>
            }
            backButton={
                <ButtonContainer>
                    { props.hasOverview && 
                        <Button size="small" onClick={goToOverview} disabled={activeSlide === 0}>
                            <FirstPageIcon />Back to Overview
                        </Button>
                    }
                    <Button size="small" onClick={handleBack} disabled={activeSlide === 0}>
                        <KeyboardArrowLeft />Prev
                    </Button>
                </ButtonContainer>
            }
        />
    </div> 
    );
}

const mapDispatchToProps= (dispatch:any) => {
    return{
        resetActiveTool: () => dispatch({ type: 'RESET_ACTIVE_TOOL' })
    }
}

export default connect(null, mapDispatchToProps)(Carousel);