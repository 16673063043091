const colors = {
    bg1: 'white',
    bg2: '#D9D9D9',
    bg3: '#BFBFBF',
    bg4: '#A6A6A6',
    grid: '#808080',
    axis: '#959696',
    markInPrep: '#36BA74',
    markOnSite: '#42E84A',
}

export default colors