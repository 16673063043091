import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
    flex: 0 1 auto;
    flex-flow: row nowrap;
    width: ${(props: { size: number, color: string }) => `${props.size}px`};
    height: ${(props: { size: number, color: string }) => `${props.size}px`};
    border-radius: ${(props: { size: number, color: string }) => `${props.size / 2}px`};
    margin-right: ${(props: { size: number, color: string }) => `${props.size / 2}px`};
    background-color: ${(props: { size: number, color: string }) => `${props.color}`};
`;

const Container = styled.div`
    width: ${(props: { width: number, color: string }) => `${props.width}px`};
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;


export interface LegendProps {
    size: number;
    width: number;
    color: string;
    title: string;
}
 
const Legend: React.SFC<LegendProps> = (props) => {
    return ( 
        <Container {...props}>
            <Circle {...props} />
            <div dangerouslySetInnerHTML={{__html: props.title}} />
        </Container>
     );
}
 
export default Legend;