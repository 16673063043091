import React from 'react';
import styled from 'styled-components';
import ArticleContainer from './container';
import { ITeamMember } from '../../content';
import Team from '../Team';

const Container = styled(ArticleContainer)`
    height:unset;
`;

export interface TeamSectionProps {
    id: string;
    team: Array<ITeamMember>;
}
 
const TeamSection: React.SFC<TeamSectionProps> = (props) => {
    return ( 
        <Container {...props} title={"Team"}>
            <Team team={props.team} />
        </Container>
     );
}
 
export default TeamSection;