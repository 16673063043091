import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import { withAuth } from '@okta/okta-react';
import styled from 'styled-components';

const LoginGrid = styled.div`
    height:100vh;
    witdh:100%;
    display:grid;
    grid-template-columns: 20% auto 20%;
    grid-template-row: repeat(3, 1fr);
    grid-template-areas:
      "top top top"
      "left form right"
      "bottom bottom bottom"
`;


const FormItem = styled.div`
    grid-area: form;
    border: 1px solid #ccc;
    border-radius: 16px;
`;

interface ILoginProps{
    auth?:any;
    baseUrl?:any;
}

interface ILoginState {
    authenticated?:any;
}

const Login = withAuth(class Login extends Component<ILoginProps, ILoginState> {
  

  constructor(props:ILoginProps) {
    super(props);
    this.state = { authenticated: null };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.checkAuthentication();
  }

  async checkAuthentication() {

    const authenticated:any = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  render() {
    if (this.state.authenticated === null) return null;
    return this.state.authenticated ?
      <Redirect to={{ pathname: '/' }}/> : 
      <LoginGrid><FormItem><LoginForm baseUrl={this.props.baseUrl} /></FormItem></LoginGrid>;
  }
});

export default Login;