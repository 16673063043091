import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import styled from 'styled-components';

const IconImage = styled.div`
margin-left: 20px;
margin-right: 20px;
`

interface IAccountImageProps {
    auth?: any;
}

interface IAccountImageState {
    authenticated?: any;
    anchorEl?:any;
}


export default withAuth(class AccountImage extends Component<IAccountImageProps, IAccountImageState> {


    constructor(props: any) {
        super(props);
        this.state = { 
            authenticated: null,
            anchorEl:null };

        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.checkAuthentication();
    }

    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            this.setState({ authenticated });
        }
    }

    componentDidUpdate() {
        this.checkAuthentication();
    }


    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({anchorEl: event.currentTarget })
    }
  
    handleClose = () => {
        this.setState({anchorEl: null })
    }


    render() {
        if (this.state.authenticated === null) return null;

        const button = this.state.authenticated ?
            <MenuItem onClick={() => { this.props.auth.logout() }}>Logout</MenuItem> :
            <MenuItem onClick={() => { this.props.auth.login() }}>Login</MenuItem>;

        return (
            <IconImage>
                <IconButton
                    edge="end"
                    aria-label="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    color="default"
                >
                    <AccountCircle color="default"/>
                </IconButton>


                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    {button}
                </Menu>
            </IconImage>

        );
    }
});